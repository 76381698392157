
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.7058 1.43055C13.1155 1.19765 13.5787 1.0752 14.05 1.0752C14.5213 1.0752 14.9845 1.19765 15.3942 1.43055C15.405 1.43671 15.4158 1.44307 15.4264 1.44964L21.7264 5.34964C21.7516 5.36529 21.7762 5.38206 21.8 5.3999C22.132 5.64892 22.4758 6.03924 22.7098 6.51294C22.7294 6.54012 22.7479 6.56854 22.7651 6.59817C22.835 6.71868 22.8769 6.84772 22.8928 6.97777C22.9608 7.2074 23 7.44977 23 7.6999V14.1999C23 14.6858 22.8178 15.1623 22.5984 15.5336C22.3748 15.9122 22.0421 16.3008 21.6145 16.5574L21.6034 16.5641L11.2942 22.5693C10.8845 22.8022 10.4213 22.9246 9.95 22.9246C9.47872 22.9246 9.01553 22.8022 8.60581 22.5693C8.59497 22.5631 8.58425 22.5567 8.57365 22.5502L2.27365 18.6502C2.24836 18.6345 2.22379 18.6177 2.2 18.5999C1.85307 18.3397 1.56821 17.9633 1.3721 17.6038C1.17968 17.251 1 16.7814 1 16.2999V9.6999C1 9.46199 1.03675 9.22354 1.10542 8.99367C1.12077 8.8487 1.16821 8.70484 1.2502 8.57265C1.27455 8.53339 1.30118 8.4964 1.32981 8.46175C1.58555 8.00134 1.97583 7.61711 2.45002 7.40473L12.7058 1.43055ZM20.344 6.84607L14.393 3.16213C14.2877 3.10511 14.1698 3.0752 14.05 3.0752C13.9266 3.0752 13.8054 3.10689 13.6978 3.16722L3.763 8.95448L10.0174 12.8338L20.344 6.84607ZM11 14.5759L13 13.4162V19.2611L11 20.4261V14.5759ZM15 18.096V12.2566L17 11.0969V16.931L15 18.096ZM19 15.7659L20.5891 14.8402C20.6618 14.7953 20.7769 14.6848 20.8766 14.5162C20.9236 14.4366 20.9573 14.3603 20.9779 14.2963C20.9994 14.2294 21 14.1971 21 14.1999V8.77757L19 9.93723V15.7659ZM3 16.2999V10.8347L9.00001 14.5562V20.4619L3.37774 16.9814C3.31878 16.9287 3.21901 16.8131 3.1279 16.646C3.08024 16.5587 3.04529 16.4757 3.02361 16.406C3.00266 16.3386 3.00025 16.3035 3 16.2999Z" fill="#1D2433"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconShipment',
    inheritAttrs: true,
  }
  </script>
